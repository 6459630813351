<template>
  <a-modal
    title="选择多个接口url"
    width="800px"
    :visible="true"
    :maskClosable="false"
    :confirmLoading="loading"
    centered
    @ok="handleSubmit"
    @cancel="
      () => {
        resetFields();
        $emit('cancel');
      }
    "
  >
    <div class="ant-pro-table-search">
      <search-render
        ref="config_render"
        :form="search_config"
        :model="search_config.model"
        :options="search_config_options"
        :validateInfos="searchValidateInfos"
        show-report
        @search="search"
      />
    </div>
    <a-table
      :rowClassName="stripe"
      :size="state.tableSize"
      :loading="state.loading"
      :dataSource="state.dataSource"
      :rowKey="state.dataSource"
    ></a-table>
  </a-modal>
</template>

<script lang="ts">
import { useForm } from 'ant-design-vue/es/form';
import { defineComponent, reactive, ref, watch } from 'vue';
import { queryApiurl } from '@/api/permission/apiurl-manage.ts';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useStore } from 'vuex';
import { queryOrgList } from '@/api/sys';
// interface TreeDataItem {
//   value: string;
//   key: string;
//   title?: string;
//   slots?: Record<string, string>;
//   children?: TreeDataItem[];
// }

const permissionActions = ['add', 'delete', 'update', 'query', 'import', 'export'];

// edit
const edit_model_config = {
  settings: {
    name: '表单名称',
    labelAlign: 'right',
    layout: 'vertical',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '菜单名称',
      label_i18n: '菜单名称',
      placeholder_i18n: '请填写菜单名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写菜单名称',
    },
    {
      type: 'select',
      name: 'platform',
      label: '平台',
      label_i18n: '平台',
      placeholder_i18n: '请选择平台',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择平台',
      datasource: 'platform',
      mode: 'default',
    },
    {
      type: 'input',
      name: 'state',
      label: '路由',
      label_i18n: '路由',
      placeholder_i18n: '请填写路由',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写路由',
    },
    {
      type: 'input',
      name: 'index',
      label: '菜单索引',
      label_i18n: '菜单索引',
      placeholder_i18n: '请填写菜单索引',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写菜单索引',
    },
    {
      type: 'input',
      name: 'parent_id',
      label: '父级菜单',
      label_i18n: '父级菜单',
      placeholder_i18n: '请选择父级菜单',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请选择父级菜单',
    },
    {
      type: 'input',
      name: 'is_menu',
      label: '菜单类型',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '菜单类型',
    },
    {
      type: 'select',
      name: 'org_ids',
      label: '隶属组织',
      label_i18n: '隶属组织',
      placeholder_i18n: '请选择组织',
      disabled: false,
      allowClear: true,
      defaultValue: '',
      placeholder: '请选择组织',
    },
    {
      type: 'select',
      name: 'url_ids',
      label: '使用接口',
      disabled: false,
      allowClear: true,
      defaultValue: '',
      placeholder: '使用接口',
    },
  ],
  rules: {
    name: [{ required: true, message: '菜单名称必须填写' }],
  },
  model: {
    id: null,
    name: '',
    desc: '',
  },
};
const edit_model_config_options = {
  platform: [
    { name: 'PC', value: 10 },
    { name: '手机端', value: 20 },
  ],
};

export default defineComponent({
  name: 'selectApiUrl',
  props: {},
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const loading = ref(false);
    const form = {
      ...edit_model_config,
      options: edit_model_config_options,
    };
    const rulesRef = reactive({ ...form.rules });
    // watchEffect(() => {
    //   if (props.form.model) {
    //     console.log('update model', props.form.model);
    //   }
    // });
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const org_list: any = reactive([]);
    queryOrgList().then(res => {
      res.data.forEach(org => {
        org_list.push(org);
      });
      console.log(org_list);
    });
    const search_model = reactive({
      org_id: current_org.id,
      is_active: true,
      is_auth: '',
      name: '',
    });
    const search_config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '作废', value: false },
      ],
      aim_org_id: org_list,
    };
    const search_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'select',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请填组织id',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
        },
        {
          type: 'input',
          name: 'name',
          label: '角色名称',
          label_i18n: '角色名称',
          placeholder_i18n: '请填写角色名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {
        name: [],
      },
      model: search_model,
    };
    const { validateInfos: searchValidateInfos } = useForm(search_model, {});
    const search = () => {
      setPageInfo({
        current: 1,
        ...search_model,
      });
      reload();
    };
    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryApiurl, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_model,
      },
    });
    const value = ref<string>();

    watch(value, () => {
      console.log(value.value);
    });
    const { resetFields, validate, validateInfos } = useForm(form.model, rulesRef);
    const handleSubmit = (e: Event) => {
      e.preventDefault();
      loading.value = true;
      validate()
        .then(() => {
          emit('ok', { ...form.model });
          loading.value = false;
        })
        .finally(() => {
          loading.value = false;
        })
        .catch(err => {
          console.log('error', err);
          loading.value = false;
        });
    };
    return {
      props,
      //search
      search_config_options,
      search_config,
      searchValidateInfos,
      search,
      //form_modal
      form,

      //table
      state,
      stripe,
      loading,
      handleSubmit,
      resetFields,
      validateInfos,
      permissionActions,
      //
      value,
      // treeData,
    };
  },
});
</script>
